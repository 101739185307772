.tutorial-line {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 7px;
    border-bottom: 1px solid rgb(202, 202, 202);
    transition: .25s;
    cursor: pointer;
    padding-inline: 5px;
}

.tutorial-line:hover {
    padding-inline: 15px;
    background-color: rgb(230, 248, 255);
}

.tutorial-line b {
    padding: 0px;
    margin: 0px;
    font-size: 1.5em;
}

video::-webkit-media-controls-timeline,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
    display: none;
}