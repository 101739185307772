
  
  .video-container {
    position: fixed; /* Garante que o vídeo sempre preencha a tela */
    top: 0;
    left: 0;
    width: 100vw; /* 100% da largura da viewport */
    height: 100vh; /* 100% da altura da viewport */
    background-color: black; /* Fundo preto caso o vídeo não preencha totalmente */
  }
  
  .react-player {
    width: 100%;
    height: 100%;
  }
  