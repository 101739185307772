.custom-speed-dial-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.custom-speed-dial-left {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.floating-button {
  font-size: 24px;
  padding: 5px;
  background-color: #1976d2; /* Cor de fundo do botão flutuante */
  color: #fff; /* Cor do texto do botão flutuante */
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.floating-button:hover {
  background-color: #1565c0; /* Cor de fundo do botão flutuante ao passar o mouse */
}

.options {
  position: absolute;
  bottom: 60px; /* Ajuste conforme necessário para alinhar com o botão e proporcionar espaço entre as opções */
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.options button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff; /* Cor do texto das opções */
  background-color: #1976d2; /* Cor de fundo das opções, mesma cor do botão flutuante */
  border: none;
  border-radius: 50%; /* Bordas arredondadas */
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.2s, margin 0.2s;
  position: relative;
}

.options button:not(:last-child) {
  margin-bottom: 8px; /* Espaçamento entre as opções */
}

.options button:hover {
  background-color: #1565c0; /* Cor de fundo das opções ao passar o mouse */
  transform: scale(1.05); /* Efeito de escala ao passar o mouse */
}


.option-text-right {
  position: absolute;
  right: calc(100% + 8px);
  opacity: 0;
  transition: opacity 0.3s;
  text-wrap: nowrap;
}

.option-text-left {
    position: absolute;
    left: calc(100% + 8px);
    opacity: 0;
    transition: opacity 0.3s;
    text-wrap: nowrap;
  }

.options button:hover .option-text-left{
  opacity: 1;
  background-color: rgb(48, 48, 48);
  border-radius: 3px;
  padding-inline: 7px;
  text-wrap: nowrap;
}

.options button:hover .option-text-right{
    opacity: 1;
    background-color: rgb(48, 48, 48);
    border-radius: 3px;
    padding-inline: 7px;
    text-wrap: nowrap;
  }