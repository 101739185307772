 .fundo {
    background-image: url('../../../public/background-login.jpg');        
    background-repeat: no-repeat;
    background-size:100%;
    bottom: 0;
    color: black;
    left: 0;
    overflow: auto;
    padding: 3em;
    position: absolute;
    right: 0;
    top: 0;
    background-size: cover;
}

@media screen and (max-width: 800px) {
    .fundo {
        background-image: url('../../../public/background-login1280x800.jpg');    
        background-repeat: no-repeat;
        background-size:100%;
        bottom: 0;
        color: black;
        left: 0;
        overflow: auto;
        padding: 3em;
        position: absolute;
        right: 0;
        top: 0;
        background-size: cover;
    }
  }

  @media screen and (max-width: 400px) {
    .fundo {
        background-image: url('../../../public/background-login700x400.jpg');    
        background-repeat: no-repeat;
        background-size:100%;
        bottom: 0;
        color: black;
        left: 0;
        overflow: auto;
        padding: 3em;
        position: absolute;
        right: 0;
        top: 0;
        background-size: cover;
    }
  }