@keyframes shine {
    0% {
        box-shadow: 0 0 10px rgba(160, 122, 17, 0.3), 
                    0 0 20px rgba(160, 122, 17, 0.2), 
                    0 0 30px rgba(160, 122, 17, 0.1);
        background-color: black;
    }
    50% {
        box-shadow: 0 0 20px rgba(160, 122, 17, 0.8), 
                    0 0 30px rgba(160, 122, 17, 0.6), 
                    0 0 50px rgba(160, 122, 17, 0.4);
        background-color: rgba(160, 122, 17, 0.8);
    }
    100% {
        box-shadow: 0 0 10px rgba(160, 122, 17, 0.3), 
                    0 0 20px rgba(160, 122, 17, 0.2), 
                    0 0 30px rgba(160, 122, 17, 0.1);
        background-color: black;
    }
}

.certificate-button {
    border-radius: 7px;
    border: 1px solid rgb(200, 200, 200);
    color: rgb(200, 200, 200);
    padding: 5px 10px;
    background-size: 100% 100%;
    transition: background-size 0.25s;
    animation: shine 1.5s infinite;
}

.certificate-button:hover {
    background-color: rgba(160, 122, 17, 0.8);
    background-size: 150% 150%;
    border: 1px solid rgb(0, 0, 0);
    box-shadow: 0 0 20px rgba(160, 122, 17, 0.8), 
                    0 0 40px rgba(160, 122, 17, 0.6), 
                    0 0 60px rgba(160, 122, 17, 0.4);
    animation: none;
}


.certificate-button:disabled {
    opacity: .5;
    background-color: rgba(107, 99, 73, 0.568);
    border: 1px solid rgb(0, 0, 0);
    box-shadow: 0 0 0;
    animation: none;
}
