/* .card:hover{

    box-shadow: 5px 10px 20px 1px rgba(62, 62, 62, 0.8) !important;
} */

.overflow{
    overflow: hidden;
}

.img-card{
    transform: scale(1);
    transition: transform 0.5s ease;
}

.img-card:hover{
    transform: scale(1.8);
}